<template>
  <div class="flex w-full" :class="containerClasses">
    <img :class="imgClasses" :style="{ width, height }" :alt="alt" :src="src" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import classNames from 'classnames';

type FitType = 'fill' | 'cover' | 'contain' | 'none';

type RatioType = 'auto' | 'square' | 'video' | '4/3' | '4/5' | '21/9';

type PlacementType = 'center' | 'start' | 'end';

type densityType = 'comfortable' | 'compact';

const fitClasses: Record<FitType, string> = {
  fill: 'object-fill',
  cover: 'object-cover',
  contain: 'object-contain',
  none: 'object-none',
};

const ratioClasses: Record<RatioType, string> = {
  auto: 'aspect-auto',
  square: 'aspect-square',
  video: 'aspect-video',
  '4/3': 'aspect-[4/3]',
  '4/5': 'aspect-[4/5]',
  '21/9': 'aspect-[21/9]',
};

const placementClasses: Record<PlacementType, string> = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
};

const densityClasses: Record<densityType, string> = {
  comfortable: 'p-5',
  compact: 'p-0',
};

interface IProps {
  src?: string;
  placement?: PlacementType;
  ratio?: RatioType;
  fit?: FitType;
  width?: string;
  height?: string;
  alt?: string;
  density?: densityType;
}

const props = withDefaults(defineProps<IProps>(), {
  src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1698918297/Plane_ypcwlc.svg',
  ratio: 'auto',
  placement: 'start',
  width: '200px',
  height: '200px',
  fit: 'contain',
  alt: '',
  density: 'compact',
});

const imgClasses = computed(() =>
  classNames(ratioClasses[props.ratio], fitClasses[props.fit]),
);
const containerClasses = computed(() =>
  classNames(placementClasses[props.placement], densityClasses[props.density]),
);
</script>
